<template>
  <div>
    <div class="fixed inset-0 z-30 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div>
    <!--   show modal   -->
    <div class="z-40 inset-0 fixed flex justify-center items-center overflow-y-auto">
      <div
        class=" flex flex-col w-16/12 sm:w-5/6 lg:w-1/2 max-w-md mx-auto rounded-lg border border-gray-300 shadow-xl">
        <div
          class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
          <p class="font-semibold text-sm text-gray-800">Voir les informations du signalement</p>
          <svg @click="closeShowQuestion" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </div>
        <div class="flex flex-col px-6 py-5 bg-gray-50">

          <div :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
            <!--<div class="fulfilling-square-spinner-show">
                  <div class="spinner-inner-show"></div>
                </div>-->
            <div class="loading">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>

          <div v-if="report != null" :class="isLoading ? 'hidden' : 'block'"
            class=" flex flex-col sm:flex-row items-center text-center text-sm p-3 sm:space-x-5">
            <div class="w-full text-left">

              <div class="border rounded-lg mb-5 bg-white shadow-sm">
                <div class="border-b px-2 py-1">
                  Question
                </div>
                <div class="p-2 br">
                  {{ report.question }}
                </div>
              </div>

              <div class="border rounded-lg mb-5 bg-white shadow-sm">
                <div class="border-b px-2 py-1">
                  Informations de l'utilisateur
                </div>
                <div class="p-2 br">
                  <p>- {{ report.firstname }} {{ report.lastname }}</p>
                  <p>- {{ report.email }}</p>
                </div>
              </div>

              <div class="border rounded-lg mb-5 bg-white shadow-sm">
                <div class="border-b px-2 py-1">
                  Type de signalement
                </div>
                <div class="p-2 br">
                  {{ report.type }}
                </div>
              </div>

              <div class="border rounded-lg mb-5 bg-white shadow-sm">
                <div class="border-b px-2 py-1">
                  Commentaire
                </div>
                <div class="p-2 br">
                  {{ report.comment }}
                </div>
              </div>

              <div class="border rounded-lg bg-white shadow-sm" v-if="report">
                <div class="border-b px-2 py-1">
                  <span v-if="report.response == nuul">Réponse</span>
                  <span v-else>Réponse <span class="text-green-500"> ( La réponse a été déja envoyée )</span> </span>
                </div>
                <div class="p-2 br" v-if="report.response == null">
                  <textarea name="" id="" class="w-full border p-2" rows="5" v-model="response"></textarea>
                </div>
                <div class="flex justify-end p-2" v-if="report.response == null">
                  <button class="primary-btn flex p-2 gap-2 items-center sm:w-auto w-full justify-center"
                    @click="sendResponse" :disabled="sendResponseLoading">
                    <span v-if="!sendResponseLoading">Envoyer</span>
                    <span v-else>
                      <Loading />
                    </span>
                  </button>
                </div>

                <div class="p-2" v-if="report.response != null">
                  <p>- {{ report.response }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg ">
          <button @click="closeShowQuestion" class="font-semibold text-gray-600 text-sm">
            Annuler
          </button>
          <!--<router-link
                :to="'/dashboard/questionsList/?year='+report.module_year+'&module='+report.module_id+'&cours='+report.course_id+'&type='+report.question_type"
                class="font-semibold text-gray-600 text-sm"
              >
                Aller
              </router-link>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from '../../../plugins/axios';
import reportRequests from '../../../services/requests/report';
import Loading from '../../icons/Loading.vue';
export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      report: null,
      response: '',
      sendResponseLoading: false,
      responseAlreadySended: false
    }
  },

  methods: {
    async sendResponse() {
      if (this.response !== '') {
        try {
          this.sendResponseLoading = true;
          const response = await reportRequests.sendResponse(this.selectedReport, this.response);
          if (response) {
            this.getReport().then(() => {

            });
          }
        } catch (error) {

        } finally {
          this.sendResponseLoading = false;
        }
      }
    },

    getReport() {
      if (this.selectedReport != null) {
        this.isLoading = true;
        axiosIns
          .get("/report/" + this.selectedReport)
          .then(({ data }) => {
            console.log(data);
            this.report = data.result[Object.keys(data.result)[0]];
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });

      }
    }
  },

  mounted() {
    this.getReport();
  },

  props: {
    closeShowQuestion: Function,
    selectedReport: Number
  },

  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
  }

};
</script>

<style>
.fulfilling-square-spinner-show,
.fulfilling-square-spinner-show * {
  box-sizing: border-box;
}

.fulfilling-square-spinner-show {
  height: 70px;
  width: 70px;
  position: relative;
  border: 4px solid #f87171;
  ;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner-show .spinner-inner-show {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>