<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="rotating-svg">
        <path d="M12 2V6" stroke="#F87171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 18V22" stroke="#F87171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.92969 4.93018L7.75969 7.76018" stroke="#F87171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.2402 16.2402L19.0702 19.0702" stroke="#F87171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2 12H6" stroke="#F87171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18 12H22" stroke="#F87171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.92969 19.0702L7.75969 16.2402" stroke="#F87171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.2402 7.76018L19.0702 4.93018" stroke="#F87171" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<style>
.rotating-svg {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>