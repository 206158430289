import axios from '@/plugins/axios';

const reportRequests = {
    async sendResponse(id, message) {
        try {
            const response = await axios.post(`/report/response/${id}`, {
                response: message
            });
            return response;
        } catch (error) {
            throw error;
        }
    }
};

export default reportRequests;